
import { defineComponent } from "vue";
import Header from "@/components/Header.vue";

export default defineComponent({
  name: "cache",
  components: {
    Header,
  },
  data() {
    return {};
  },
  methods: {},
});
